var boxTypes = {
    "Panel": {
        "items_per_pallet": 33,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "Panel-G1": {
        "items_per_pallet": 33,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "Panel-M10": {
        "items_per_pallet": 30,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "B": {
        "items_per_pallet": 48,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "B-52": {
        "items_per_pallet": 52,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "C": {
        "items_per_pallet": 182,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "D": {
        "items_per_pallet": 252,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "H": {
        "items_per_pallet": 2400,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "I": {
        "items_per_pallet": 8000,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "J": {
        "items_per_pallet": 4200,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "K": {
        "items_per_pallet": 4200,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "L": {
        "items_per_pallet": 2400,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "N": {
        "items_per_pallet": 0,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "N-1": {
        "items_per_pallet": 1800,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "P": {
        "items_per_pallet": 90,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "Q": {
        "items_per_pallet": 48,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "R": {
        "items_per_pallet": 24,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "O": {
        "items_per_pallet": 0,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "O-1": {
        "items_per_pallet": 24000,
            "pallet_type": "Portrait",
                "can_go_on_other_pallet_type": true
    },
    "E": {
        "items_per_pallet": 48,
            "pallet_type": "Landscape",
                "can_go_on_other_pallet_type": false
    },
    "E-52": {
        "items_per_pallet": 52,
            "pallet_type": "Landscape",
                "can_go_on_other_pallet_type": false
    },
    "E-38": {
        "items_per_pallet": 38,
            "pallet_type": "Landscape",
                "can_go_on_other_pallet_type": false
    },
    "F": {
        "items_per_pallet": 0,
            "pallet_type": "Landscape",
                "can_go_on_other_pallet_type": false
    },
    "G": {
        "items_per_pallet": 0,
            "pallet_type": "Landscape",
                "can_go_on_other_pallet_type": false
    }
}

var products = {
    "PV16-405-M10": "Panel-M10",
    "PV16-335-G1": "Panel-G1",
    "PV16-340-G1W": "Panel-G1",
    "PV16-270P-B": "Panel",
    "PV16-270P-W": "Panel",
    "F16-TL": "B",
    "F16-TC": "B-52",
    "F16-TR": "B",
    "F16-TY": "C",
    "F16-J": "D",
    "VAT-16": "B",
    "F16-CLT": "B",
    "F16-CRT": "B",
    "F16-CRB": "B",
    "F16-CLB": "B",
    "F16-CLB-S": "B",
    "F16-VC": "B",
    "F16-VBC": "B",
    "F16-LL": "E",
    "F16-LC": "E",
    "F16-LR": "E",
    "F16-LY": "C",
    "F16-LJ": "D",
    "VAL16": "E",
    "F16-LCLT": "E",
    "F16-LCRT": "E",
    "F16-LCRB": "E",
    "F16-LCLB": "E",
    "F16-LCLB-S": "E",
    "F16-TL-G1": "B-52",
    "F16-TC-G1": "B-52",
    "F16-TR-G1": "B-52",
    "F16-TY-G1": "C",
    "F16-J-G1": "D",
    "VAT-16-G1": "B-52",
    "F16-CLT-G1": "B-52",
    "F16-CRT-G1": "B-52",
    "F16-CRB-G1": "B-52",
    "F16-CLB-G1": "B-52",
    "F16-CLB-S-G1": "B-52",
    "F16-VC-G1": "B",
    "F16-VBL-G1": "B",
    "F16-VB-G1": "B",
    "F16-VBR-G1": "B",
    "F16-LL-G1": "E-52",
    "F16-LC-G1": "E-52",
    "F16-LR-G1": "E-52",
    "F16-LY-G1": "C",
    "F16-LJ-G1": "D",
    "VAL16-G1": "E-52",
    "F16-LCLT-G1": "E-52",
    "F16-LCRT-G1": "E-52",
    "F16-LCRB-G1": "E-52",
    "F16-LCLB-G1": "E-52",
    "F16-LCLB-S-G1": "E-52",
    "F16-TL-M10": "B-52",
    "F16-TC-M10": "B-52",
    "F16-TR-M10": "B-52",
    "F16-TY-M10": "C",
    "F16-J-M10": "D",
    "VAT-16-M10": "B-52",
    "F16-CLT-M10": "B-52",
    "F16-CRT-M10": "B-52",
    "F16-CRB-M10": "B-52",
    "F16-CLB-M10": "B-52",
    "F16-CLB-S-M10": "B-52",
    "F16-VL-M10": "E-38",
    "F16-VC-M10": "B",
    "F16-VR-M10": "E-38",
    "F16-VBL-M10": "E-38",
    "F16-VBC-M10": "E-38",
    "F16-VBR-M10": "E-38",
    "F16-LL-M10": "E-52",
    "F16-LC-M10": "E-52",
    "F16-LR-M10": "E-52",
    "F16-LY-M10": "C",
    "F16-LJ-M10": "D",
    "VAL16-M10": "E-52",
    "F16-LCLT-M10": "E-52",
    "F16-LCRT-M10": "E-52",
    "F16-LCRB-M10": "E-52",
    "F16-LCLB-M10": "E-52",
    "F16-LCLB-S-M10": "E-52",
    "B22": "K",
    "B22J": "L",
    "SP-5": "I",
    "SP-5J": "J",
    "SB16-L": "H",
    "SB16-C": "H",
    "SB16-R": "H",
    "SB16-Y": "H",
    "SB16-J": "H",
    "SB16-AT": "H",
    "SB16-CLB": "H",
    "CSI1000": "Q",
    "CSI1500": "Q",
    "CSI2000": "Q",
    "CSI2500": "Q",
    "CSI3000": "Q",
    "CSI3600": "R",
    "CSI5000": "R",
    "CSI6000": "R",
    "CEV-S7": "P",
    "ARC-04": "N-1",
    "ARC-BM-25(1)": "O-1",
    "ARC-BM-30(1)": "O-1",
    "ARC-BM-38(1)": "O-1",
    "ARC-M01-1": "O-1",
    "ARC-M02-1": "O-1",
    "ARC-M03-1": "O-1",
    "ARC-M04-1": "O-1"
}

function calculatePallets(quote) {
    var totalPortrait = 0;
    var totalLandscape = 0;

    var portraitToLandscape = 0;
    var landscapeToPortrait = 0;

    for (const product of quote) {

        if (product.quantity > 0) {
            var boxType = products[product.viridianId];
            var boxTypeInfo = boxTypes[boxType];

            if (!boxTypeInfo.items_per_pallet) {
                debugger;
			}

            var pallets = product.quantity / boxTypeInfo.items_per_pallet;

            if (boxTypeInfo.pallet_type == "Portrait") {
                totalPortrait += pallets;

                if (boxTypeInfo.can_go_on_other_pallet_type) {
                    portraitToLandscape += pallets;
				}
            }
            else {
                totalLandscape += pallets;
			}
        }
    }

    var landscapeExtra = totalLandscape - Math.floor(totalLandscape);
    var portraitExtra = totalPortrait - Math.floor(totalPortrait);

    if (totalLandscape > 0 && landscapeExtra > 0) {
        if (totalPortrait > 0 && portraitExtra > 0) {
            var amountToPutOnLandscapePallet = Math.min(portraitToLandscape, 1 - landscapeExtra);

            totalLandscape += amountToPutOnLandscapePallet;
            totalPortrait -= amountToPutOnLandscapePallet;
		}
    }

    return { portrait: Math.ceil(totalPortrait - 0.000001), landscape: Math.ceil(totalLandscape - 0.000001) }
}

export default calculatePallets;